.Persik {
  display: block;
  width: 30%;
  max-width: 240px;
  margin: 20px auto;
}
.deleteHead{
  border:2px solid red;
}

.flex_div h5{
  flex-grow: .7;
  align-self: center;
}

.actor_images{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.acter_images{
  width:40%;
  height: 40%;
  margin: 5%;
}
.photo_acter{
  position: relative;
  padding-top: 5px;
  width: 83%;
  /* height: 300px; */
  left:50%;
  transform: translate(-50%);
}
.buttonJ{
  margin:15px;
}
.new{
  padding-left:15px;
  padding-right: 15px;
}
.flex_div{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  }
  
  .flex_div h4{
    margin-left: 40px;
    flex-basis: 90%;
  }

.flex_div1{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  
}

.flex_div1 h4{
  margin-left: 40px;
  flex-wrap: wrap;
  flex-basis: 90%;
}

.date{
  height: 100%;
  display: flex;
  margin-bottom: 5px;
  flex-wrap: nowrap;
  align-items: flex-end;
  color:#808c98;
}
.date .actor_date{
  left:-10px;
  position: relative;
  white-space: nowrap;
}
.actor_img{
  width:33px;
  height:33px;
  align-self: center;
}
.unselectable {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -khtml-user-select: none;    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;           /* Non-prefixed version, currently
                                  not supported by any browser */
}
.mycursor {
  cursor: pointer;	
  }

  .panel-noscroll {
    overflow: scroll;
    overflow-x: hidden;
   /* margin-left: 9px;*/
  }

  .centerpanel{
    flex: 0.5;
    justify-self: 'center';
    align-items: 'center';
    text-align: 'center';
    vertical-align: text-bottom
    }
    .nowrap{
      white-space: nowrap;
    }
    .heighttext{
      display:inline-block;
      padding:15px 10px;
      line-height:140%;
  }
  .errorname {position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;}


    .scroll-menu-arrow {
      padding: 5px;
      cursor: pointer;
    }
    .noprobel {white-space: nowrap; overflow: hidden; text-overflow: ellipsis; max-width: 110px;}